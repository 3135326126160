import React from 'react';
import { Layout } from 'antd';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Content from '../../components/Content';

const BasicLayout: React.FC = () => (
  <Layout>
    <Header />
    <Content />
    <Footer />
  </Layout>
);

export default BasicLayout;