import { FC, useState, useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'antd/dist/reset.css';
import Cookies from "js-cookie";
import { Router } from './router/index'
import { useDispatch, useSelector } from 'react-redux';
import { changeIsLogin, changeLoginIng, changeUserInfo } from './store/modules/loginSlice';
import { getDetail, getLogin, getSmsCode, joinLab } from './api/modules/common';
import Config from './config';
import ExamNotice from './components/ExamNotice';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { receiveData, setNeedReconnect, setWebSocket } from './store/modules/websocketSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getWorkInvite } from './api/modules/study';
import InviteModal from './components/InviteModal';
import { generateRandomTimeString } from './utils/randomTime';
import { CookieExistsLogin } from './utils/login';
import { useLogin } from './hooks/Login';
import { getLocation, getYunYanLoginSlient } from './utils/url';
import config from './config';

const App: FC = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { logout, login } = useLogin()

  const { isLogin } = useSelector((state: any) => state.loginUser)

  //邀请学习是否现实
  const [inviteShow, setInviteShow] = useState<boolean>(false)

  //学习路径
  const [inviteUrl, setInviteUrl] = useState<any>('')

  const [websocket, setWebsocket] = useState<any>()

  //考试弹窗
  const [examOpen, setExamOpen] = useState<any>(false)

  //考试弹窗
  const [examValue, setExamValue] = useState<any>(0)

  const [search] = useSearchParams()

  const repo = search.get('repo') || ''

  const sign = search.get('sign') || ''
  const modal = search.get('modal') || ''

  // 需要重连
  const [noteReconnect, setNoteReconnect] = useState(false)


  //被邀请者跳转工作台
  const jumpWork = () => {
    getWorkInvite({
      repo: decodeURIComponent(repo),
      sign: sign.replace(/ /g, '+'),
    }).then(res => {
      setInviteShow(true)
      const { openApiAddr, access, partner, courseName } = res
      const url = `${openApiAddr}?modal=${modal || '0'}&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${''}&courseName=${encodeURIComponent(courseName)}&time=${generateRandomTimeString()}`
      setInviteUrl(url)
    })
  }

  // 全局监听cookie是否存在，即是否已经登录过了
  useEffect(() => {
    if (Cookies.get('token')) {
      getDetail().then((res: any) => {
        if (!res.isLogin) {
          logout()
          return
        } else if (repo && sign && modal) {
          jumpWork()
        }
        const { token, partner, userId } = res
        login({ token, sign: res.sign, partner, userId }, res)
        // login(undefined, res)
      })
    } else {
      if (repo && sign && modal) {
        window.open(getYunYanLoginSlient(`?type=onlylogin&redirect=${getLocation()}`), '_self')
      }
    }
  }, [])

  // 将以前的登录窗口统一定向到登录页  暂时先这样处理
  useEffect(() => {
    if (isLogin) {
      dispatch(changeIsLogin({ isLogin: false }))
      window.open(getYunYanLoginSlient(`?redirect=${getLocation()}`), '_self')
    }
  }, [isLogin])


  const handleWS = () => {
    try {
      if (websocket) {
        websocket?.close()
      }
      const ws = new WebSocket(`${Config.wsURL}/api/v0.3/notice/exam?userId=${Cookies.get('userId')}`);
      setWebsocket(ws)

      ws.onopen = function () {
        setNoteReconnect(false)
      }

      ws.onmessage = function (data1: any) {



        const data = JSON.parse(data1.data)
        try {
          if (data?.unreadCount > 0 && window?.location?.pathname !== '/answer-exam') {
            setExamOpen(true)
            setExamValue(data?.unreadCount || 0)
          }

        } catch (error) {

        }
      }

      ws.onclose = function (res: any) {
        setNoteReconnect(true)
      }
    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    if (Cookies.get('userId')) {
      handleWS()
    } else {
      if (websocket) {
        websocket?.close()
      }
    }
  }, [Cookies.get('userId')]);

  useEffect(() => {
    if (noteReconnect) {
      handleWS()
    }
  }, [noteReconnect])

  useEffect(() => {
    return () => {
      if (websocket) {
        websocket?.close()
      }
    }
  }, [])

  message.config({
    duration: 2, // 默认的自动关闭时间（单位：秒）
  });


  const location = useLocation()

  useEffect(() => {
    const host = window.location.host
    const title = document.title
    if (host.includes(config.yunyanURLTop!)) {
      if (title !== '云燕实验室') {
        document.title = '云燕实验室'
        document.querySelector('link[rel="icon"]')?.setAttribute('href', '/yunyan.png')
      }
    } else {
      if (title !== '开源学堂') {
        document.title = '开源学堂'
        document.querySelector('link[rel="icon"]')?.setAttribute('href', '/favicon.ico')
      }
    }
  }, [location])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1677FF',
        },
      }
      }
      locale={zhCN}
    >
      {<ExamNotice open={examOpen} value={examValue} onchange={() => {
        setExamOpen(false)
      }}></ExamNotice>}
      {<InviteModal ifshow={inviteShow} modal={modal} url={inviteUrl} changeIfshow={async () => {
        await joinLab({
          repo: decodeURIComponent(repo),
          sign: sign.replace(/ /g, '+'),
        }).catch(() => {})
        setInviteShow(false)
        // navigate(`/`)
      }}></InviteModal>}
      <Router />
    </ConfigProvider>
  )
};

export default App;

