// 题目类型
export enum QuesType {
    'radio' = '单选题',
    'checkbox' = '多选题',
    'answer' = '问答题',
    // 'practice' = '实操题'
    'acm' = '函数脚本类实操题',
    'project' = '工程项目类实操题',
    'ccm' = '编码测验题'
}

// 题目难度
export enum DifficultyType {
    'low' = '简单',
    'middle' = '中等',
    'high' = '困难'
}

// 实验室的状态
export enum LabStatus {
    init = 'INIT',        // 初始化
    created = 'CREATED',  // 已发布
    under = 'UNDER'       // 下架
}

/**
 * 实验室类型
 * @description 实验室类型，分为项目实验室0、个人实验室1、机构实验室2(暂时未使用)、教培实验室3、官方实验室10
 */
export enum LabType {
    PROJECT,
    PERSONAL,
    ORGAN,
    EDUCATION,
    OFFICIAL = 10,
}

