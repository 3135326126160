import React, { useState, useEffect, useRef } from 'react'
import "./index.less"
import { Select, message, Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { saveStudyQuestion, runAcm, queryRunAcmResult, queryAcmLanguageCustomList } from '../../../../../../api/modules/exam'
import CodeEditor from '../../../../../../components/CodeEditor'
import Line from '../Line'

interface ContainerRigthProps {
  value: any       // 实验室数据
  disableCopyPaste?: boolean
}

const ContainerRigth: React.FC<ContainerRigthProps> = (props) => {

  const navigate = useNavigate()

  const location = useLocation();

  const [params] = useSearchParams();
  //问题code
  let questionCode = params.getAll("questionCode")[0]

  //考试记录code
  let examRecordCode = params.getAll("examRecordCode")[0]
  let bank = params.getAll("bank")[0]

  //题目类型 add：添加考试的试卷题目； mark：评分模式； view：查看模式； exam：考试中； preview：预览
  const status = params.getAll("status")[0]

  const { value, disableCopyPaste } = props

  const [codeLanguageName, setCodeLanguageName] = useState('')

  // 代码编辑器
  const CodeEditorRef = useRef<any>(null)

  //自测
  const [selfTesting, setSelfTesting] = useState(false)

  //输入
  const [inputValue, setInputValue] = useState('');

  //输出
  const [outputValue, setOutputValue] = useState('');

  //语言选择
  const [language, setLanguage] = useState<any>()

  //现有的语言内容
  const [languagesList, setLanguagesList] = useState<any>()

  // 用户临时保存的代码
  const [userTempCodes, setUserTempCodes] = useState<any>({})

  //默认语言
  const [defaultValue, setDefaultValue] = useState<any>()

  //暂时保存code
  const [saveCode, setSaveCode] = useState<any>(undefined)

  // 保存定时器
  const [intervalTimer, setIntervalTimer] = useState<any>()

  // 保存loading
  const [loadingSave, setLoadingSave] = useState(false)

  // 运行loading
  const [runLoading, setRunLoading] = useState(false)

  // 运行结果
  const [runResult, setRunResult] = useState<any>()

  //输入
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  }

  // 期望输出
  const handleOutChange = (e: any) => {
    setOutputValue(e.target.value)
  }

  // 保存并提交
  const saveAndSubmit = () => {
    if (loadingSave) return
    setLoadingSave(true)
    let text = CodeEditorRef?.current?.getCode()
    let newText = text?.replace(/\n/g, "\r\n");
    //保存考试
    saveStudyQuestion({
      answer: newText,
      examRecordCode: examRecordCode,
      questionCode: questionCode,
      language: defaultValue?.value ?? defaultValue
    }).then(() => {
      setLoadingSave(false)
      message.success('保存成功！')
      navigate(location.state?.from ?? "/", {
        state: {
          from: location,
          scrollTop: location?.state?.scrollTop,
        }
      })
    }).catch(res => {
      setLoadingSave(false)
      message.destroy()
      message.error(res?.message)
    })
  }

  // 编程语言change
  const selectChange = (selectValue: any) => {
    setDefaultValue(selectValue)
    setCodeLanguageName(selectValue?.toLowerCase())
    // 编辑器滚动到顶部
    CodeEditorRef?.current?.scrollToTop()
    // 评分、查看模式下：显示用户提交的代码
    if ((status === 'mark' || status === 'view') && value?.userOptionJson?.language === selectValue) {
      const userAnswer = value?.userAnswer?.replace(/\r\n/g, "\n")
      if (userAnswer) {
        CodeEditorRef?.current?.setCode(userAnswer)
        return
      }
    }
    // 考试中，有实时保存的数据
    if (status === 'exam' && userTempCodes[selectValue]) {
      const newTempAnswer = userTempCodes[selectValue]?.replace(/\r\n/g, "\n");
      CodeEditorRef?.current?.setCode(newTempAnswer)
    } else {
      const obj = languagesList?.find((item: any) => item?.languageName === selectValue)
      CodeEditorRef?.current?.setCode(obj?.customCode || '')
    }
  }

  // 运行
  const run = () => {

    //判断是否点击重新运行
    if (runResult) {
      setInputValue('')
      setOutputValue('')
      setRunResult('')
      return
    }
    const sourceCode = CodeEditorRef?.current?.getCode()
    if (!sourceCode || !sourceCode?.trim()) {
      message.destroy()
      message.warning('未输入代码，请输入代码后再运行')
      return
    }
    setRunLoading(true)
    const languageName = defaultValue?.value ?? defaultValue
    const obj = languagesList.find((v: any) => v?.languageName === languageName)
    runAcm({
      cases: [inputValue],
      lang: obj?.languageAlias,
      questionId: questionCode,
      sourceCode: CodeEditorRef?.current?.getCode()
    }).then(res => {
      //让元素滚动到最顶部

      const testId = res?.data?.testId
      if (testId) {
        queryRunResult(testId)
      } else {
        setRunLoading(false)
      }
    }).catch(res => {
      message.destroy()
      message.error(res?.message)
      setRunLoading(false)
    })
  }

  // 间隔1s，轮询查询运行结果
  const queryRunResult = (id: string) => {
    let timeout = 0;
    const interval = setInterval(() => {
      if (timeout > value?.optionJson?.runTimeout * 2) {
        clearInterval(interval)
        setRunLoading(false)
        message.error('请求超时，请重试！')
        return
      }
      timeout++
      queryRunAcmResult(id).then(res => {
        const data = res?.data
        if (data?.state === 'COMPILED') {
          const item = document.getElementsByClassName('footer-validate')[0]
          if (item && item.scrollTop > 0) {

            item.scrollTop = 0;
            item.scroll({
              top: 0,
              behavior: 'smooth'
            });
          }
          clearInterval(interval)
          setRunLoading(false)
          setRunResult({
            compileSuccess: data?.compileSuccess,
            compilerMessage: data?.compilerMessage,
            result: data?.result
          })
        }
      }).catch(() => { })
    }, 1000)
  }

  useEffect(() => {
    //获取语言模版
    if (value) {
      //选择初始语言，代码高亮语言
      const _langage = value?.userOptionJson?.language || value?.optionJson?.defaultLanguage;
      setDefaultValue(
        { label: _langage, value: _langage }
      )
      setCodeLanguageName(_langage?.toLowerCase())
      // 用户实时保存的代码
      if (value?.userOptionJson?.cache && Object.keys(value?.userOptionJson?.cache)?.length) {
        setUserTempCodes(value?.userOptionJson?.cache)
      }
      // 已提交：显示提交的代码；未提交：显示默认代码
      queryAcmLanguageCustomList({ questionCode, questionType: 1 }).then(res => {
        setLanguagesList(res)
        if (status === 'mark' || status === 'view') {
          // 评分、查看模式：显示用户提交的代码
          const userAnswer = value?.userAnswer?.replace(/\r\n/g, "\n")
          if (userAnswer) {
            CodeEditorRef?.current?.setCode(userAnswer)
          } else {
            const obj = res?.find((item: any) => item?.languageName === _langage)
            CodeEditorRef?.current?.setCode(obj?.customCode || '')
          }
        } else {
          if (value?.userOptionJson?.language) {
            if (value?.userOptionJson?.cache?.hasOwnProperty(value?.userOptionJson?.language)) {
              let newTempAnswer = value.userOptionJson.cache[value?.userOptionJson?.language]?.replace(/\r\n/g, "\n");
              CodeEditorRef?.current?.setCode(newTempAnswer)
            } else {
              const obj = res?.find((item: any) => item?.languageName === value?.userOptionJson?.language)
              CodeEditorRef?.current?.setCode(obj?.customCode || '')
            }
          } else if (value?.optionJson?.defaultLanguage) {
            if (bank === '1') {
              CodeEditorRef?.current?.setCode(value?.codeList?.[0]?.customCode || '')
            } else {
              const obj = res?.find((item: any) => item?.languageName === value?.optionJson?.defaultLanguage)
              CodeEditorRef?.current?.setCode(obj?.customCode || '')
            }
          }
        }

        // 编程语言下拉选择框数据
        let languageList: any = []
        res.forEach((item: any) => {
          if (value?.optionJson?.languages?.includes(item?.languageName)) {
            languageList.push({ label: item.languageName, value: item.languageName })
          }
        })
        setLanguage(languageList)
      })
    }
  }, [value])

  // 3s保存一次编辑器代码
  useEffect(() => {
    if (status === 'exam' && saveCode !== undefined) {
      if (intervalTimer) {
        clearTimeout(intervalTimer)
      }
      const intervalId = setTimeout(() => {
        const newText = saveCode?.replace(/\n/g, "\r\n");
        const language = defaultValue?.value ?? defaultValue
        setUserTempCodes({
          ...userTempCodes,
          [language]: newText
        })
        saveStudyQuestion({
          tempAnswer: newText,
          examRecordCode: examRecordCode,
          questionCode: questionCode,
          language
        })
        clearTimeout(intervalId)
        setIntervalTimer('')
      }, 3000);
      setIntervalTimer(intervalId)
      return () => {
        intervalTimer && clearTimeout(intervalTimer)
      };
    }
  }, [saveCode]);

  return (
    <div className='container'>
      <div className='container-heard'>
        <div className='container-heard-select'>
          <Select
            disabled={language?.length === 1}
            value={defaultValue}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            options={language}
            onChange={selectChange}
          >
          </Select>
          <div className='icon'>
            <img src={require('../../../../../../assets/images/downWork.png')} style={{ width: '16px' }} alt="" />
          </div>
        </div>
      </div>
      <div className='container-element container-element-codeEditor'>
        <CodeEditor disableCopyPaste={disableCopyPaste} ref={CodeEditorRef} language={codeLanguageName} readOnly={status === 'mark'} onChange={(value: any) => {
          setSaveCode(value)
        }}></CodeEditor>
      </div>
      <div className='container-footer'>
        {
          selfTesting && <div className='container-footer-line'>
            <Line state="row" move={(x: number) => {
              const el0: any = document.getElementsByClassName('container-footer')[0]
              el0.style.height = el0?.clientHeight - x + 'px'
            }} />
          </div>
        }
        <div className='footer-btns'>
          <div className='container-footer-left'
            style={{
              background: !selfTesting ? '' : 'rgba(22,119,255,0.10)'
            }}
            onClick={() => {
              setSelfTesting(!selfTesting)
              const el0: any = document.getElementsByClassName('container-footer')[0]
              el0.style.height = 'auto'
            }}
          >
            <span>自测</span>
          </div>
          {status === 'exam' && <Button type='primary' className='container-footer-rigth' onClick={saveAndSubmit}>保存并提交</Button>}
        </div>
        {selfTesting && <div className='footer-validate'>
          {
            runResult && (
              runResult?.compileSuccess ? (
                <>
                  {
                    runResult?.result[0]?.success && outputValue?.trim() === runResult?.result[0]?.output?.trim() ? (
                      <div className='validate-success'>
                        <div className='validate-success-text'>运行结果：答案正确</div>
                      </div>
                    ) : (
                      <div className='validate-error'>
                        <div className='validate-error-text'>
                          {
                            runResult?.result[0]?.success ? '运行结果：答案错误' : runResult?.result[0]?.exception
                          }
                        </div>
                      </div>
                    )
                  }
                </>
              ) : (
                <div className='validate-error'>
                  <div className='validate-error-text'>编译出错</div>
                  <div className='validate-error-msg'>
                    <p>您提交的代码无法完成编译</p>
                    {runResult?.compilerMessage}
                  </div>
                </div>
              )
            )
          }
          {
            (!runResult || (runResult?.compileSuccess && runResult?.result[0]?.success)) && (
              <div>
                {
                  !runResult && <span className='footer-validate-prompt'>请输入测试用例：</span>
                }
                <div className='validate-input'>
                  <span>输入</span>
                  <TextArea disabled={runResult} autoSize value={inputValue} onChange={handleInputChange} />
                </div>
                <div className='validate-input'>
                  <span>期望输出</span>
                  <TextArea disabled={runResult} autoSize value={outputValue} onChange={handleOutChange} />
                </div>
                {
                  runResult && runResult?.result?.length ? (
                    <div className='validate-input'>
                      <span>实际输出</span>
                      <TextArea autoSize readOnly value={runResult?.result[0]?.output?.trim()} />
                    </div>
                  ) : <></>
                }
              </div>
            )
          }
          <div className='validate-run'>
            <Button type='primary' disabled={!inputValue.trim() || !outputValue.trim()} loading={runLoading} onClick={run}>{runResult ? '重新测试' : '运行'}</Button>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default ContainerRigth;
