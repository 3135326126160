import axios from "axios";
import config from "../../config";
import http, { httpInstanve } from "../index";

// 获取实验室权限
export async function getLabAuth(labCode: string) {
    return http.get(`/lab/user/isJoin`, {labCode})
}

// 信息扩展  添加字段保存
export async function saveInfoExtend(params: any) {
    return http.post(`/lab/info/extend/save`, params)
}

// 信息扩展  列表查询 
export async function getInfoExtendList(labCode: string) {
    return http.post(`/lab/info/extend/list?labCode=${labCode}`)
}

// 信息扩展  列表查询 
export async function delInfoExtend(extendCode: string) {
    return http.delete(`/lab/info/extend/delete/${extendCode}`)
}

// 用户管理  开启信息收集
export async function openCollect(data: any) {
    return http.post(`/lab/change/extendCollect`, data)
}

// 用户管理  标签列表查询 
export async function getTagsList(labCode: string) {
    return http.get(`/lab/user/label/list?labCode=${labCode}`)
}

// 用户管理  创建标签   重命名标签
export async function createTag(data: any) {
    return http.post(`/lab/user/label/save`, data)
}

// 用户管理  列表获取  分页
export async function getUserManageList(data: any) {
    return http.post(`/lab/user/page`, data)
}

// 用户管理  获取某个标签的所有用户
export async function getLabelUserList(params: any) {
    return http.get(`/lab/user/list`, params)
}

// 用户管理  删除标签
export async function deleteTag(labelCode: string) {
    return http.delete(`/lab/user/label/delete/${labelCode}`)
}

// 用户管理  打标签
export async function setUsersTags(data: any) {
    return http.post(`/lab/user/label/tag`, data)
}

// 用户管理  标签转化列表查询
export async function queryTransList(data: any) {
    return http.post(`/lab/info/extend/search`, data)
}

// 用户管理  扩展标签转化
export async function transformTags(data: any) {
    return http.post(`/lab/user/label/resolve/infoExtend`, data)
}





// 课程管理 导出 课程搜索
export async function searchCourse(data: any) {
    return http.post(`/course/search`, data)
}

// 课程管理 导出
export async function exportCourse(data: any) {
    return httpInstanve.post(`/statistics/course/export`, data, {responseType: 'blob'})
}

// 课程管理 导出 当前是否有在导出
export async function getIsExport(labCode: string) {
    return http.get(`/statistics/course/isExport`, {labCode})
}











// 共创者邀请 设置为管理员
export async function setAsAdministrator(data: any) {
    return http.post(`/lab/share/setAsAdministrator`, data)
}

// 变更展示快速体验状态  minilab
export async function changeQuickExperience(data: any) {
    return http.post(`/lab/change/quick-experience`, data)
}


// 分页查询用户课程进度
export async function getCourseProgress(data: any) {
    return http.post(`/statistics/course/page`, data)
}

// 查询用户课程统计详情
export async function getCourseUserProgress(data: any) {
    return http.get(`/statistics/course/single/detail`, data)
}

// 同步课程数据
export async function syncCourse(code: string) {
    return http.post(`/statistics/course/sync?code=${code}`)
}
// 同步课程数据 状态同步
export async function syncCourseStatus(code: string) {
    return http.get(`/statistics/course/sync/status`, {code})
}

// 同步课程用户数据
export async function syncCourseUser(code: string, userId: string) {
    return http.post(`/statistics/course/single/sync?code=${code}&userId=${userId}`)
}

// 同步课程用户数据 状态同步
export async function syncCourseUserStatus(code: string, userId: string) {
    return http.get(`/statistics/course/single/sync/status`, {code, userId})
}

// 用户管理刷新  
export async function syncUserRefresh(data: any) {
    return http.post(`/statistics/course/stats/refresh`, data)
}

// 用户管理刷新   状态同步 
export async function syncUserRefreshStatus(data: any) {
    return http.post(`/statistics/course/stats/refresh/status`, data)
}



// 主页  获取实验室模板
export async function getLabTempList() {
    return http.post(`/lab/template/list`)
}

// 主页  我的创作  获取实验室
export async function getLabManage(data: any) {
    return http.post(`/lab/myLabQuery`, data)
}


// 主页  我的任务  消息
export async function getTaskMessage(data: any) {
    return http.post(`/notice-record/mytask-notice-message-page`, data)
}

export async function getTaskMessageDetail(messageId: string) {
    return http.get(`/notice-record/info?messageId=${messageId}`)
}
export async function sendRecord(messageId: string) {
    return http.get(`/notice-record/receipt?messageId=${messageId}`)
}

export async function getTaskNum() {
    return http.get(`/notice-record/quantity`)
}

// 动态获取菜单
export async function getLabMenu(labCode: string, platform: string = 'oscollege') {
    return http.get(`/sys/menu/listByLab`, {labCode, platform})
}


// 热门课程查询
export async function queryCourseList(data: any, cancelToken?: any) {
    return http.post(`/course/popular`, data, {cancelToken: cancelToken ? new axios.CancelToken(cancelToken) : undefined})
}

// 热门实验室查询
export async function queryLabList(data: any, cancelToken?: any) {
    return http.post(`/lab/popular`, data, {cancelToken: cancelToken ? new axios.CancelToken(cancelToken) : undefined})
}


