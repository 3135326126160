import React from 'react';
import './index.less';
import { Outlet } from "react-router-dom";
import Sider from '../Sider';

const Content: React.FC = () => {
  return (
      <div className="content-container">
        <div className='content-container-content'>
          <Sider />
          <div className='content-container-content-main'>
            <Outlet />
          </div>
        </div>
      </div>
  );
}

export default Content;